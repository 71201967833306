import ApiService from "../../services/api.service.js";
import AlertService from "../../services/errors.service.js";

export const state = {
  purchases: [],
  onePurchase: [],
  payments: [],
  isLoading: false,
};

export const mutations = {
  SET_PURCHASE(state, newValue) {
    state.purchases = newValue;
  },
  SET_PAYMENT(state, newValue) {
    state.payments = newValue;
  },
  SET_ONE_PURCHASE(state, newValue) {
    state.onePurchase = newValue;
  },
  SET_LOADING(state, newValue) {
    state.isLoading = newValue;
  },

  ADD_PURCHASE(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.purchases.unshift(newData);
  },
  ADD_PAYMENT(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.payments.unshift(newData);
  },

  UPDATE_TOTAL(state, { itemIndex, total }) {
    if (
      state.onePurchase &&
      state.onePurchase.items &&
      state.onePurchase.items[itemIndex]
    ) {
      state.onePurchase.items[itemIndex].total = total;
    }
  },
  INCREMENT_QUANTITY(state, itemIndex) {
    if (
      state.onePurchase &&
      state.onePurchase.items &&
      state.onePurchase.items[itemIndex]
    ) {
      state.onePurchase.items[itemIndex].quantity++;
    }
  },
  DECREMENT_QUANTITY(state, itemIndex) {
    if (
      state.onePurchase &&
      state.onePurchase.items &&
      state.onePurchase.items[itemIndex] &&
      state.onePurchase.items[itemIndex].quantity > 0
    ) {
      state.onePurchase.items[itemIndex].quantity--;
    }
  },

  UPDATE_GRAND_TOTAL(state) {
    if (state.onePurchase && state.onePurchase.items) {
      state.onePurchase.total = state.onePurchase.items.reduce(
        (total, item) => total + item.total,
        0
      );
    }
  },

  REMOVE_ITEM(state, index) {
    if (
      state.onePurchase &&
      state.onePurchase.items &&
      state.onePurchase.items.length > index
    ) {
      state.onePurchase.items.splice(index, 1);
    }
  },
  ADD_ITEM(state, newItem) {
    if (state.onePurchase && state.onePurchase.items) {
      state.onePurchase.items.push(newItem);
    }
  },

  updateItemDescription(state, { index, description }) {
    state.onePurchase.items[index].description = description;
  },

  updateItemPrice(state, { index, price }) {
    const item = state.onePurchase.items[index];
    if (item) {
      // Treat empty strings as 0
      item.price = price === "" ? 0 : parseFloat(price);
      // Recalculate the total for this item
      item.total = item.price * item.quantity;
      // Trigger grand total recalculation
      state.onePurchase.total = state.onePurchase.items.reduce(
        (total, currentItem) => total + currentItem.total,
        0
      );
    }
  },
};

export const getters = {
  getPurchases(state) {
    return state.purchases;
  },
  getOnePurchase(state) {
    return state.onePurchase;
  },

  getPayments(state) {
    return state.payments;
  },
  getLoading(state) {
    return state.isLoading;
  },
};

export const actions = {
  async getPaymentList({ commit, dispatch, getters }, query) {
    try {
      commit("SET_LOADING", true);

      const response = await ApiService.get(
        "/finance/finance-request?" + new URLSearchParams(query)
      );

      if (response.status === 200) {
        const data = response.data;
        commit("SET_PAYMENT", data);
      }
    } catch (error) {
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async getPaymentsRequest({ commit, dispatch, getters }, query) {
    try {
      const response = await ApiService.get(
        "api/finance/finance-request?" + new URLSearchParams(query)
      );

      if (response.status === 200) {
        const data = response.data.data;
        commit("SET_PAYMENT", data);
      }
    } catch (error) {}
  },

  async getPurchaseRequestById({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.get("/purchase-request/" + id);

      if (response.status === 200) {
        const data = response.data.data;
        commit("SET_ONE_PURCHASE", data);
      }
    } catch (error) {}
  },

  async addPurchase({ commit, dispatch, getters }, data) {
    try {
      data.currency_type = "usd";
      data.type = "purchase";
      data.items = [{ details: "book", price: 1500, quantity: 3 }];
      const response = await ApiService.post("/finance/finance-request", data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.purchaseRequest;
        commit("ADD_PURCHASE", data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async UpdatePurchase({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.post(
        "/purchase-request/" + payload.id,
        payload.data
      );

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const data = response.data.purchaseRequest;
        commit("UPDATE_PURCHASE", data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  // calculate

  updateTotal({ commit, state }, { itemIndex, price, quantity }) {
    // Calculate the total based on the updated price or quantity
    const total = price * quantity;
    // Commit the mutation to update the total in the state
    commit("UPDATE_TOTAL", {
      itemIndex,
      total,
    });
  },

  incrementQuantity({ commit }, itemIndex) {
    // Commit the mutation to increment the quantity
    commit("INCREMENT_QUANTITY", itemIndex);
  },

  decrementQuantity({ commit }, itemIndex) {
    // Commit the mutation to decrement the quantity
    commit("DECREMENT_QUANTITY", itemIndex);
  },

  updateGrandTotal({ commit }) {
    commit("UPDATE_GRAND_TOTAL");
  },

  removeItem({ commit }, index) {
    commit("REMOVE_ITEM", index);
    commit("UPDATE_GRAND_TOTAL");
  },

  addItem({ commit, dispatch }, newItem) {
    commit("ADD_ITEM", newItem);
    dispatch("updateGrandTotal");
  },

  updateItemPrice({ commit, dispatch }, { index, price }) {
    commit("UPDATE_ITEM_PRICE", {
      index,
      price,
    });
    dispatch("updateGrandTotal");
  },
};
