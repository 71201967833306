<template>
  <router-view></router-view>
</template>

<script>
import { authComputed, authMethods } from "./state/helpers";

export default {
  name: "App",
  components: {},
  methods: {
    ...authMethods,
    ...authComputed,
    fetchData() {
      this.$store.dispatch("groups/fetchGroups");
      this.$store.dispatch("user/getAllUser");
    },
  },

  mounted() {
    // get theme from local storage if available and set it to the document element
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      document.documentElement.setAttribute("data-bs-theme", savedTheme);
    }
  },

  created() {
    if (localStorage.getItem("access_token")) {
      this.getUsersData();
      this.fetchData();
    }
  },
};
</script>
