import { ref } from "vue";
import ApiService from "../services/api.service";
import AlertService from "../services/errors.service";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default function useProjects() {
  const Projects = ref({});
  const Project = ref([]);
  const deletedProjectId = ref(null);
  const router = useRouter();
  const store = useStore();

  const getProjects = async (query) => {
    return await ApiService.get("/project/list?" + new URLSearchParams(query));
    // Projects.value = response.data;
  };

  const getProject = async (id) => {
    let response = await ApiService.get("/project/single/" + id);
    Project.value = response.data.data;
  };

  const getProjectEditData = async (id) => {
    let response = await ApiService.get("/project/" + id + "/edit");
    Project.value = response.data.data;
  };

  const storeProject = async (data) => {
    try {
      const response = await ApiService.post("/project", data);

      if (response.status === 201) {
        store.dispatch("auth/getUsersData");

        router.push({
          name: "projects",
        });
        await new Promise((resolve) => setTimeout(resolve, 10));
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const updateProject = async (id, data) => {
    try {
      const response = await ApiService.post("project/update/" + id, data);

      if (response.status === 201) {
        store.dispatch("auth/getUsersData");
        // router.push({ name: 'projects' });
        // await new Promise(resolve => setTimeout(resolve, 10));
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const destroyProject = async (id) => {
    try {
      const response = await ApiService.delete("/project/" + id);

      if (response.status === 200) {
        store.dispatch("auth/getUsersData");

        AlertService.successMessage(response.data.message);
        deletedProjectId.value = response.data.id;
      }
    } catch (error) {}
  };

  return {
    Project,
    Projects,
    deletedProjectId,
    getProject,
    getProjectEditData,
    getProjects,
    storeProject,
    updateProject,
    destroyProject,
  };
}
