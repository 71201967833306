import { ref } from "vue";
import ApiService from "../services/api.service";
import AlertService from "../services/errors.service";
import { useRouter } from 'vue-router';
export default function usePositions() {
    const Positions = ref({});
    const Position = ref([]);
    const selectPosition = ref([]);
    const newPosition = ref({});
    const editedPosition = ref({});
    const deletedPositionId = ref(null);
    const responseResult = ref(false)

    const getPositions = async (query) => {
        return await ApiService.get('/position/list?' + new URLSearchParams(query));
        // Positions.value = response.data;
    }

    const getPosition = async (id) => {
        let response = await ApiService.get('/position/single/' + id);
        Position.value = response.data.data;
    }

    const selectPositions = async () => {
        let response = await ApiService.get('/position/select');
        selectPosition.value = response.data.data;
    }

    const storePosition = async (data) => {
        try {
            const response = await ApiService.post('/position', data);

            if (response.status === 201) {
                AlertService.successMessage(response.data.message);
                newPosition.value = response.data.data;
                responseResult.value = true;
            }
        } catch (error) {
            responseResult.value = false;
        }
    }

    const updatePosition = async (id, data) => {
        try {
            const response = await ApiService.put('position/update/' + id, data);

            if (response.status === 200) {
                AlertService.successMessage(response.data.message);
                editedPosition.value = response.data.data;
                responseResult.value = true;
            }
        } catch (error) {
            responseResult.value = false;
        }
    }

    const destroyPosition = async (id) => {
        try {
            const response = await ApiService.delete('/position/' + id);

            if (response.status === 200) {
                AlertService.successMessage(response.data.message);
                deletedPositionId.value = id;
                responseResult.value = true;
            }
        } catch (error) {
            responseResult.value = false;
        }
    }

    return { Position , Positions , selectPosition , newPosition , editedPosition , deletedPositionId , responseResult , getPosition , getPositions , selectPositions , storePosition , updatePosition , destroyPosition};
}