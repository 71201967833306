import {
  ref
} from "vue";
import ApiService from "../services/api.service";
import AlertService from "../services/errors.service";
import {
  useRouter
} from "vue-router";
export default function useUsers() {
  const Users = ref({});
  const User = ref([]);
  const selectUser = ref([]);
  const responseResult = ref(false);

  const getUsers = async (query) => {
    return await ApiService.get("/user?" + new URLSearchParams(query));
    // let response = await ApiService.get('/user?' + new URLSearchParams(query));
    // Users.value = response.data;
  };

  const getUser = async (id) => {
    let response = await ApiService.get("/user/" + id);
    User.value = response.data.data;
  };

  const getUserData = async () => {
    return await ApiService.get("/user/permissions");
  };

  // get user profile but id is not optional

  const getUserProfile = async (id = null) => {
    let response;
    if (id == null) {
      response = await ApiService.get("/user/profile");
    } else {
      response = await ApiService.get("/user/profile/" + id);
    }
    User.value = response.data.data;
  };

  const selectUsers = async () => {
    let response = await ApiService.get("/user/select2");
    selectUser.value = response.data.data;
  };

  const getUserEditData = async (id) => {
    try {
      let response = await ApiService.get("/user/" + id + "/edit");
      User.value = response.data.data;
    } catch (error) {
      responseResult.value = false;
    }
  };

  const storeUser = async (data) => {
    try {
      const response = await ApiService.post("/user", data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        console.log(response);
        // responseResult.value = true;
        // getUsers();
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const updateUser = async (id, data) => {
    try {
      if (data.parent_id == null) {
        delete data.parent_id;
      }

      const response = await ApiService.patch("user/" + id, data);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
        getUsers();
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const destroyUser = async (id) => {
    try {
      const response = await ApiService.delete("/user/" + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
      }
    } catch (error) {}
  };

  const changeUserStatus = async (id) => {
    try {
      const response = await ApiService.get("/user/" + id + "/status");

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        responseResult.value = true;
      }
    } catch (error) {
      responseResult.value = false;
    }
  };

  const changeUserPassword = async (data) => {
    try {
      const response = await ApiService.put("/user/password", data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const changeUserAvatar = async (data) => {
    try {
      const response = await ApiService.post("/user/image", data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        localStorage.removeItem("userImage");
        localStorage.setItem("userImage", JSON.stringify(response.data.image));
      }
    } catch (error) {}
  };

  return {
    User,
    Users,
    selectUser,
    responseResult,
    getUser,
    getUsers,
    getUserData,
    getUserProfile,
    changeUserPassword,
    changeUserAvatar,
    getUserEditData,
    selectUsers,
    storeUser,
    updateUser,
    destroyUser,
    changeUserStatus,
  };
}