import ApiService from "../../services/api.service.js";
import AlertServie from "@/services/errors.service";

export const state = {
  reservations: [],
  dates: [],
  employees: [],
};

export const mutations = {
  SET_RESERVATIONS(state, newValue) {
    state.reservations = newValue;
  },
  SET_DATES(state, newValue) {
    state.dates = newValue;
  },
  SET_EMPLOYEES(state, newValue) {
    state.employees = newValue;
  },
};

export const getters = {
  getReservations(state) {
    return state.reservations;
  },
  getDates(state) {
    return state.dates;
  },
  getEmployees(state) {
    return state.employees;
  },
};

export const actions = {
  async getReservations({ commit, dispatch, getters }, payload) {
    try {
      var currentDate = new Date();
      const sixMonthsBefore = new Date(currentDate);
      sixMonthsBefore.setMonth(sixMonthsBefore.getMonth() - 6);

      const sixMonthsAfter = new Date(currentDate);
      sixMonthsAfter.setMonth(sixMonthsAfter.getMonth() + 6);

      var fromDate = sixMonthsBefore.toISOString().split("T")[0];
      var toDate = sixMonthsAfter.toISOString().split("T")[0];

      var bookableId = payload.room > 0 ? "&bookable_id=" + payload.room : "";
      var carFilter = "";

      if (payload.type === "car" && payload.car_type) {
        if (payload.car_type != "") {
          carFilter = "&car_type=" + payload.car_type;
        }
      }

      var bookableId = payload.room > 0 ? "&bookable_id=" + payload.room : "";

      var baseUrl = payload.myReservations ? "my-reservations" : "reservations";

      const response = await ApiService.get(
        "/" +
          baseUrl +
          "?bookable_type=" +
          payload.type +
          "&start_date=" +
          fromDate +
          "&end_date=" +
          toDate +
          bookableId +
          carFilter
      );
      if (response.status === 200) {
        const data = response.data.data;
        commit("SET_RESERVATIONS", data);
      }
    } catch (error) {}
  },

  async checkAvailableRoomDates({ commit, dispatch, getters }, payload) {
    try {
      if (payload.start_date != "" && payload.start_date != undefined) {
        var room = payload.room > 0 ? "&bookable_id=" + payload.room : "";
        var endDate =
          payload.end_date == ""
            ? "&end_date=" + prepareDateFormat(payload.start_date)
            : "&end_date=" + prepareDateFormat(payload.start_date);
        var bookableType = "&bookable_type=room";
        const response = await ApiService.get(
          "/reservations/check-date?start_date=" +
            payload.start_date +
            endDate +
            room +
            bookableType
        );
        if (response.status === 200) {
          const data = response.data;
          commit("SET_DATES", data);
        }
      }
    } catch (error) {}
  },

  async checkAvailaveCarDates({ commit, dispatch, getters }, payload) {
    try {
      if (payload.start_date != "" && payload.start_date != undefined) {
        var carType =
          payload.car_type != "" ? "&car_type=" + payload.car_type : "";
        var endDate =
          payload.end_date == ""
            ? "&end_date=" + prepareDateFormat(payload.start_date)
            : "&end_date=" + prepareDateFormat(payload.start_date);
        var bookableType = "&bookable_type=car";

        const response = await ApiService.get(
          "/reservations/check-date?start_date=" +
            payload.start_date +
            endDate +
            carType +
            bookableType
        );
        if (response.status === 200) {
          const data = response.data;
          commit("SET_DATES", data);
        }
      }
    } catch (error) {}
  },

  async saveEvent({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.post("/booking", payload);
      if (response.status === 201) {
        AlertServie.successMessage("The event has been successfully added.");
        this.getReservations();
      }
    } catch (error) {}
  },

  async editEvent({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put("/booking/" + payload.id, payload);
      if (response.status === 200) {
        AlertServie.successMessage("The event has been successfully edited.");
        this.getReservations();
      }
    } catch (error) {}
  },
  async deleteEvent({ commit, dispatch, getters }, id) {
    try {
      const response = await ApiService.delete("/booking/" + id);
      if (response.status === 200) {
        AlertServie.successMessage("The event has been successfully deleted.");
        this.getReservations();
      }
    } catch (error) {}
  },

  async approveReservation({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put(
        "/booking/" + payload.id + "/approve",
        {
          car_id: payload.car_id,
          driver_id: payload.driver_id,
        }
      );
      if (response.status === 200) {
        const data = response.data;
        AlertServie.successMessage("The event has been successfully approved.");

        this.getReservations();
      }
    } catch (error) {}
  },

  async rejectReservation({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put(
        "/booking/" + payload.id + "/reject"
      );
      if (response.status === 200) {
        const data = response.data;
        AlertServie.successMessage("The event has been successfully rejected.");

        this.getReservations();
      }
    } catch (error) {}
  },
};

function prepareDateFormat(date) {
  const dateStr = date;
  const formattedDateStr = dateStr && dateStr?.split("T")[0];
  return formattedDateStr;
}
