// import { useAbility } from '@casl/vue';
import ability from '../services/ability';


import store from '../state/store';


const permissions = localStorage.getItem('permissions');




function array_has(arr, obj) {
  return (arr.indexOf(obj) != -1);
}

function can(permission) {
  return array_has(permissions, permission);
}



const MiddlewareService = {
  init(router) {
    router.beforeEach(async (to, from, next) => {
      if (to.matched.some(record => record.meta.authRequired)) {
        if (!localStorage.getItem('access_token')) {
          next("/");
        } else {
          // Check if the user has the required permission
          const requiredPermission = to.meta.requiresPermission;

          if (requiredPermission && !can(requiredPermission)) {
            next("/404");
          } else {
            next();
          }

        }
      } else {
        next();
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    });
  },
};

export default MiddlewareService;