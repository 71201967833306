import useProjects from '../../composable/project'
import AlertService from '../../services/errors.service';
import ApiService from '../../services/api.service';

const {
  getProjects
} = useProjects();


export const state = {
  projects: [],
  project: [],
  groups: [],
}

export const mutations = {
  SET_PROJECTS(state, newValue) {
    state.projects = newValue
  },
  SET_GROUPS(state, newValue) {
    state.groups = newValue
  },
  deleteItem(state, id) {
    const index = state.projects.data.findIndex(item => item.id === id);
    console.log(index)
    state.projects.data.splice(index, 1)
  }
}

export const getters = {
  getProjects(state) {
    return state.projects
  },
  getGroups(state) {
    return state.groups
  },


}

export const actions = {

  getProjectsData({
    commit,
    dispatch,
    getters
  }, query) {

    return getProjects(query).then(resp => {
      const response = resp.data;
      commit('SET_PROJECTS', response)

    });

  },

  async getGroups({
    commit,
    dispatch,
    getters
  }, id) {

    const response = await ApiService.get('/groups/' + id);
    if (response.status === 200) {
      const data = response.data.data;
      commit('SET_GROUPS', data)
    }
  },

  async deleteProject({
    commit
  }, id) {
    try {
      const response = await ApiService.delete('/project/' + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit('deleteItem', id)
      }
    } catch (error) {
      AlertService.errorMessage(error)
    }
  },



}