import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska";

import VueFeather from "vue-feather";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import "@/assets/scss/config/default/app.scss";
import "@vueform/slider/themes/default.css";
import "@/assets/scss/mermaid.min.css";
import "primevue/resources/themes/lara-light-green/theme.css";

import { abilitiesPlugin } from "@casl/vue";
import ability from "./services/ability";

import AlertServie from "./services/errors.service";

import ErrorService from "./services/errorHandler.service";

import MiddlewareServie from "./services/middlewre.service";
import ApiService from "./services/api.service";
import timeago from "vue-timeago3";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

// FakeBackend authentication
// import { configureFakeBackend } from './helpers/fake-backend';
// configureFakeBackend();

// Firebase authentication
// import { initFirebaseBackend } from './authUtils';

// const firebaseConfig = {
//     apiKey: process.env.VUE_APP_APIKEY,
//     authDomain: process.env.VUE_APP_AUTHDOMAIN,
//     databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//     projectId: process.env.VUE_APP_PROJECTId,
//     storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//     messagingSenderId: process.env.VUE_APP_MEASUREMENTID
// };

// initFirebaseBackend(firebaseConfig);

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

const app = createApp(App);

app.use(router);

AlertServie.init(app);

ErrorService.init();

ApiService.init(app);
app.use(store);
app.use(ElementPlus);
app.use(VueApexCharts);
app.use(BootstrapVue3);
app.component(VueFeather.type, VueFeather);
app.directive("maska", vMaska);
app.use(i18n);
app.use(timeago);
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
});

MiddlewareServie.init(router);

app.use(vClickOutside).mount("#app");
