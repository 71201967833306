
import useClients from '../../composable/client.js';
import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

const {getClients,updateClient} = useClients();

export const state = {
    clients: [],
    client: [],
    firstClient:{}
}

export const mutations = {
    SET_CLIENTS(state,newValue){
        state.clients=newValue
    },
    FILTER_CLIENT(state,newValue){
        state.clients=state.client
    },
    updateItem(state,{id,data}){
        const index = state.clients.findIndex(item => item.id === id);

        const newData=JSON.parse(JSON.stringify(data))
      state.clients[index]=newData
        console.log(newData)
    },
    addItem(state,data){

      const newData=JSON.parse(JSON.stringify(data))
      state.clients.unshift(newData)
    },
    deleteItem(state,id){

        const index = state.clients.findIndex(item => item.id === id);
        state.clients.splice(index,1)
    }
}

export const getters = {
  getclients(state){
    return state.clients
  },

  getFirstClient(state){
    return state.clients[0]
  }



}

export const actions = {

    getClients({ commit, dispatch, getters },query) {

        return  getClients(query).then(resp=>{
               const response=resp.data.data;
               commit('SET_CLIENTS',response)
          });

         },

   async UpdateClient({ commit, dispatch, getters },payload) {

                try {
                    const response = await ApiService.post('client/update/' + payload.id, payload.data);
                    if (response.status === 200) {
                        // AlertService.successMessage(response.data.message);
                        const id=payload.id;
                        const data=response.data.data
                        commit('updateItem', { id, data});
                        // console.log(response.data.data)
                        // responseResult.value = true;
                        // editedClient.value = response.data.data;
                    }
                } catch (error) {
                    // responseResult.value = false;
                }

         },


         async addClient({ commit, dispatch, getters },data) {


                const response = await ApiService.post('/client', data);
                if (response.status === 201) {
                    AlertService.successMessage(response.data.message);
                    const newItem=response.data.data
                    commit('addItem',newItem)
                }

     },

     async deleteClient({commit},id){
        try {
            const response = await ApiService.delete('/client/' + id);

            if (response.status === 200) {
                AlertService.successMessage(response.data.message);
                commit('deleteItem',id)
            }
        } catch (error) {
            // responseResult.value = false;
        }
     },



         async searchAndFilterclients({ commit, state }, searchQuery) {
            const normalizedQuery = searchQuery.toLowerCase().trim();
            if (!normalizedQuery) {
              // If the query is empty, set filtered clients to all clients
              commit('SET_CLIENTS', state.clients);
            } else {
                const filteredclients = state.clients.map(client =>
                    client.fullname.toLowerCase().includes(normalizedQuery)
                  );
                  commit('SET_CLIENTS', filteredclients);
            }
          },

}


