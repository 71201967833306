import axios from "axios";
import ApiServices from "../../services/api.service";

export const state = {
    charts: [],
};

export const getters = {
    getCharts: state => state.charts,
};

export const mutations = {
    setCharts(state, newValue) {
        state.charts = newValue
    },
};

export const actions = {


    async fetchCharts({ commit, dispatch, getters }) {


            const response = await ApiServices.get("/user/organization-chart");
            const data = response.data
            commit('setCharts',data)


         },
};
