import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
const AlertServie = {
  init(app) {
  },
  successMessage(title = 'Success') {
    toast.success(title); 
  },
  errorMessage(title = 'Error') {
    toast.error(title);
  },
  warningMessage(title = 'Warning') {
    toast.warning(title);  
  },
  infoMessage(title = 'Info') {
    toast.info(title);  
  }
};

export default AlertServie;
