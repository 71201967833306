import ApiService from "../../services/api.service.js";
import AlertService from "../../services/errors.service.js";

export const state = {
  groups: [],
  allGroups: [],
  allGroupsHasUsers: [],
};

export const getters = {
  getGroups: (state) => state.groups,
  getAllGroups: (state) => state.allGroups,
  getAllGroupsHasUsers: (state) => state.allGroupsHasUsers,
};

export const mutations = {
  setGroups(state, newValue) {
    state.groups = newValue;
  },

  setGroupsHasUsers(state, newValue) {
    state.allGroupsHasUsers = newValue;
  },

  setAllGroups(state, newValue) {
    state.allGroups = newValue;
  },
  ADD_GROUPS(state, data) {
    const newData = JSON.parse(JSON.stringify(data));
    state.allGroups.unshift(newData);
    state.allGroupsHasUsers.unshift(newData);
  },
  DELETE_GROUPS(state, id) {
    const index = state.allGroups.findIndex((item) => item.id === id);
    state.allGroups.splice(index, 1);
    state.allGroupsHasUsers.splice(index, 1);
  },
  UPDATE_GROUPS(state, { id, data }) {
    const index = state.allGroups.findIndex((item) => item.id === id);
    console.log(data);
    const newData = data;
    state.allGroups[index] = newData;
    state.allGroupsHasUsers[index] = newData;
  },
};

export const actions = {
  async fetchGroups({ commit, dispatch, getters }) {
    const response = await ApiService.get("/groups/select");
    const data = response.data.data;
    commit("setGroups", data);
    commit("setAllGroups", data);
  },

  async fetchGroupshasUser({ commit, dispatch, getters }) {
    const response = await ApiService.get("/groups");
    const data = response.data.data;
    commit("setGroupsHasUsers", data);
  },

  async addGroup({ commit, dispatch, getters }, data) {
    try {
      const response = await ApiService.post("/groups", data);

      if (response.status === 201) {
        AlertService.successMessage(response.data.message);
        const data = response.data.data;
        commit("ADD_GROUPS", data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async deleteGroups({ commit }, id) {
    try {
      const response = await ApiService.delete("/groups/" + id);

      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        commit("DELETE_GROUPS", id);
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },

  async updateGroup({ commit, dispatch, getters }, payload) {
    try {
      const response = await ApiService.put(
        "groups/" + payload.id,
        payload.data,
        false,
        { headers: { "Content-Type": `application/json` } }
      );
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        const id = payload.id;
        const data = response.data.data;
        commit("UPDATE_GROUPS", {
          id,
          data,
        });
        // console.log(response.data.data)
        // responseResult.value = true;
        // editedClient.value = response.data.data;
      }
    } catch (error) {
      // responseResult.value = false;
    }
  },
};
