import useDepartments from '../../composable/department.js';
import ApiService from '../../services/api.service.js';
import AlertService from '../../services/errors.service.js';

const {
    getDepartments
} = useDepartments();

export const state = {
    departments: [],
    department: [],
}

export const mutations = {
    SET_DEPARTMENTS(state, newValue) {
        state.departments = newValue
    },
    FILTER_DEPARTMENTS(state, newValue) {
        state.departments = state.department
    },
    ADD_DEPARTMENT(state, data) {

        const newData = JSON.parse(JSON.stringify(data))
        state.departments.data.unshift(newData)
    },
    DELETE_DEPARTMENT(state, id) {
        const index = state.departments.data.findIndex(item => item.id === id);
        state.departments.data.splice(index, 1)
    },
    UPDATE_DEPARTMENT(state, {
        id,
        data
    }) {
        const index = state.departments.data.findIndex(item => item.id === id);

        const newData = JSON.parse(JSON.stringify(data))
        state.departments.data[index] = newData
        console.log(newData)
    },
}

export const getters = {
    getdepartments(state) {
        return state.departments
    },




}

export const actions = {

    getDepartments({
        commit,
        dispatch,
        getters
    }, query) {

        return getDepartments(query).then(resp => {
            const response = resp.data;
            commit('SET_DEPARTMENTS', response)
        });

    },



    async addDepartment({
        commit,
        dispatch,
        getters
    }, data) {
        try {
            const response = await ApiService.post('/department', data);

            if (response.status === 201) {
                AlertService.successMessage(response.data.message);
                const data = response.data.data
                commit('ADD_DEPARTMENT', data)
            }
        } catch (error) {
            console.log(error)
        }
    },


    async deleteDepartment({
        commit
    }, id) {
        try {
            const response = await ApiService.delete('/department/' + id);

            if (response.status === 200) {
                AlertService.successMessage(response.data.message);
                commit('DELETE_DEPARTMENT', id)
            }
        } catch (error) {
            // responseResult.value = false;
        }
    },


    async updateDepartment({
        commit,
        dispatch,
        getters
    }, payload) {

        try {
            const response = await ApiService.put('department/update/' + payload.id, payload.data);
            if (response.status === 201) {
                AlertService.successMessage(response.data.message);
                const id = payload.id;
                const data = response.data.data
                commit('UPDATE_DEPARTMENT', {
                    id,
                    data
                });
                // console.log(response.data.data)
                // responseResult.value = true;
                // editedClient.value = response.data.data;
            }
        } catch (error) {
            // responseResult.value = false;
        }

    },




    async searchAndFilterdepartments({
        commit,
        state
    }, searchQuery) {
        const normalizedQuery = searchQuery.toLowerCase().trim();
        if (!normalizedQuery) {
            // If the query is empty, set filtered departments to all departments
            commit('SET_departments', state.departments);
        } else {
            const filtereddepartments = state.departments.map(department =>
                department.fullname.toLowerCase().includes(normalizedQuery)
            );
            commit('SET_departments', filtereddepartments);
        }
    },

}