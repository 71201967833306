import { ref } from "vue";
import ApiService from "../services/api.service";
import AlertService from "../services/errors.service";

export default function useTaskStatus() {
  const storeProjectStatus = ref(false);
  const editProjectStatus = ref(false);
  const deletedStatusId = ref(null);
  const taskStatuses = ref([]);
  const oldTaskStatuses = ref([]);
  const newOrderStatues = ref([]);
  const getTaskStatus = async (id, isAlltask = true) => {
    try {
      const response = isAlltask
        ? await ApiService.get("/project/" + id + "/task_tatus")
        : await ApiService.get(
            "/project/" +
              id +
              "/task_tatus?" +
              new URLSearchParams({
                assignedTasksOnly: true,
              })
          );

      taskStatuses.value = response.data.data;
      newOrderStatues.value = response.data.data;
      oldTaskStatuses.value = JSON.parse(JSON.stringify(taskStatuses.value));
    } catch (error) {}
  };

  const changeStatusOfTask = async (taskID, statusID, id, isAlltask) => {
    try {
      const response = await ApiService.put(
        "/task/" + taskID + "/status-update",
        {
          task_status_id: statusID,
        }
      );
      AlertService.successMessage(response.data.message);
      isAlltask ? getTaskStatus(id, true) : getTaskStatus(id, false);
    } catch (error) {}
  };

  const changeOrderStatus = async (id) => {
    try {
      var ids = taskStatuses.value.map((item) => item.id);
      const response = await ApiService.get(
        "/task_status/" + id + "/sort-update/" + ids
      );
      AlertService.successMessage(response.data.message);
    } catch (error) {}
  };

  const storeStatus = async (data) => {
    try {
      const response = await ApiService.post("/task_status", data);
      if (response.status === 201) {
        storeProjectStatus.value = true;
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };
  const addSingleTask = async (data, id, isAlltask) => {
    try {
      const response = await ApiService.post("/task", data);
      if (response.status === 201) {
        // taskStatuses.value = response.data.data;
        // newOrderStatues.value = response.data.data;
        // oldTaskStatuses.value = JSON.parse(JSON.stringify(taskStatuses.value));
        isAlltask ? getTaskStatus(id, true) : getTaskStatus(id, false);
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const UpdateSingleTask = async (task_id, project_id, data, isAlltask) => {
    try {
      const response = await ApiService.post("/task/" + task_id, data);
      if (response.status === 200) {
        // taskStatuses.value = response.data.data;
        // newOrderStatues.value = response.data.data;
        // oldTaskStatuses.value = JSON.parse(JSON.stringify(taskStatuses.value));
        isAlltask
          ? getTaskStatus(project_id, true)
          : getTaskStatus(project_id, false);
        AlertService.successMessage(response.data.message);
      }
    } catch (error) {}
  };

  const deleteSingleTask = async (task_id, _project_id, isAlltask) => {
    try {
      const response = await ApiService.delete("/task/" + task_id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        isAlltask
          ? getTaskStatus(_project_id, true)
          : getTaskStatus(_project_id, false);
      }
    } catch (error) {}
  };

  const showSingleTaskDetail = async (id) => {
    try {
      return await ApiService.get("/task/" + id);
    } catch (error) {
      return [];
    }
  };

  const updateStatus = async (id, data) => {
    try {
      const response = await ApiService.put("/task_status/" + id, data);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        editProjectStatus.value = true;
      }
    } catch (error) {}
  };

  const destroyStatus = async (id) => {
    try {
      const response = await ApiService.delete("/task_status/" + id);
      if (response.status === 200) {
        AlertService.successMessage(response.data.message);
        deletedStatusId.value = id;
      }
    } catch (error) {}
  };

  const addComment = async (data) => {
    try {
      return await ApiService.post("/comments", data);
    } catch (error) {
      return [];
    }
  };

  return {
    storeProjectStatus,
    editProjectStatus,
    deletedStatusId,
    taskStatuses,
    oldTaskStatuses,
    newOrderStatues,
    changeStatusOfTask,
    getTaskStatus,
    changeOrderStatus,
    storeStatus,
    updateStatus,
    destroyStatus,
    addSingleTask,
    showSingleTaskDetail,
    addComment,
    deleteSingleTask,
    UpdateSingleTask,
  };
}
